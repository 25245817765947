
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --white: #ffffff;
  --black: #000000;
  --primary: #42cbd3;
  --primaryAlter: #1f9ba7;
  --primaryBG: linear-gradient(189.08deg, #1f9ba7 57.94%, #19808a 112.48%);
  --primaryGradient: linear-gradient(217.07deg, #42cbd3 0%, #1f9ba7 68.77%);
  --primaryText: var(--white);
  --secondary: #c7ae6e;
  --secondaryText: #3a2610;
  --thirdText: #b2913e;
  --fourthText: #c0a664;
  --fifthText: #4d4d4d;
  --red: #ff0e46;
  --containerWidth: 1230px;
  --containerSmWidth: 1030px;
  --buttonBg: var(--primaryGradient);
  --mainFont: "Sofia Pro";
  --headerFont: "Philosopher";
  --subText: "Playfair Display";
    overflow-x: hidden;
}


* {
  /* -ms-overflow-style: none; */
  box-sizing: border-box !important;
  /* margin: 0 !important;
  padding: 0 !important; */
}
::-webkit-scrollbar {
  /* display: none; */
}

body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  max-width: 100% !important;
  overflow-x: hidden !important;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
