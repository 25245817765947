@import url('https://fonts.googleapis.com/css2?family=Anuphan&family=Open+Sans&display=swap');

.new-review-main-container{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
}

.new-review-secondary-container{
    margin-top: 5rem;
    margin-bottom: 5rem;
    width: 80%;
    height: auto;
    min-height: 500px;
    box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.new-review-individual-card{
    padding: 2rem 5.5rem;
    width: 100%; 
    height: auto;
    display: flex;
    flex-direction: column;
}

.new-review-heading{
    display: flex;
    align-items: center;
}

.new-review-heading h3{
    font-size: 1.17em;
    font-weight: 600;
}

.new-review-heading span{
    margin-left: 0.5rem;
    font-size: smaller;
    font-weight: 100;
}


.new-review-ratings-container {
  /* border: 1px solid red; */
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .column {
    flex-basis: 27%;
    padding: 0 10px;
    /* border: 1px solid black; */
  }
  
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .label {
    font-size: 1.1rem;
    margin-right: 10px;
  }
  

  .five-star-rating{
    height: 18px;
    margin-left: auto;
  }

 

  .rating-column{
    /* border: 1px solid red; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .rating-score{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: 1px solid blue; */
    margin-left: 2.5rem;
  }

  .rating-score img{
    margin-top: 0.3rem;
    margin-right: 0.4rem;
  }

  .rating-number{
    font-size: 1.4rem;
    color: #f9af02;
    font-weight: 700;
  }

  .rating-text{
    font-size: 1.2rem;
    font-weight: 700;
  }

  .new-review-comment,
  .new-review-to-improve{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
  }

  .new-review-comment-heading,
  .new-review-to-improve-heading{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .new-review-comment-heading img,
  .new-review-to-improve-heading img{
    width: 31px;
    height: 31px;
  }

  .new-review-comment-heading span,
  .new-review-to-improve-heading span{
    font-size: 1rem;
    font-weight: 700;
    margin-left: 1rem;
  }

  .new-review-comment-description,
  .new-review-to-description{
    margin-top: 1rem;
    font-size: 1rem;
  }

  .new-review-secondary-container .carousel-control-prev-icon,
  .new-review-secondary-container .carousel-control-next-icon{
    background-color: #f9af02;
  }

  .carousel-control-prev-icon{
    margin-right: 5rem;
  }

  .carousel-control-next-icon{
    margin-left: 5rem;
  }


@media (max-width: 1100px){
  .item{
    flex-direction: column;
  }
  .column{
    width: 50%;
  }

  .third-column{
    flex-basis: 100%;
  }
  .rating-column{
    align-items: center;
    justify-content: center;
  }
  .rating-text{
    text-align: center;
  }
}

@media (max-width: 1000px){

   .new-review-secondary-container .carousel-control-prev-icon{
    margin-right: 3rem;
  }

  .new-review-secondary-container .carousel-control-next-icon{
    margin-left: 3rem;
  }
}

@media (max-width: 500px){
  .new-review-secondary-container{
    width: 90%;
  }

  .new-review-individual-card{
    padding: 2rem 3rem;
  }
  .new-review-secondary-container .carousel-control-prev-icon{
    margin-right: 1rem;
  }

  .new-review-secondary-container .carousel-control-next-icon{
    margin-left: 1rem;
  }

  .new-review-heading h3{
    font-size: 1rem;
  }
  .new-review-ratings-container{
    flex-direction: column;
  }
  .column{
    width: 100%;
  }
  .item{
    flex-direction: row;
  }
}

@media (max-width: 370px){
  .label{
    font-size: 1rem; 
  }
}