.float-icon{
    position: fixed;
    bottom: 30px;
    right: 50px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #25D366;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    color: white;
}
.float-icon a{
    text-decoration: none;
    color: black;
}

.floating-button {
    position: fixed;
    top: 30vh; /* adjust this value to position the button vertically */
    right: 30px; /* adjust this value to position the button horizontally */
    background-color: #CFAE60;
    color: #455010;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    /* border: 3px solid #455010; */
    z-index: 9999; /* ensure the button is above other elements */
  }

  
.floating-button:hover {
    background-color: #455010;
    color: #CFAE60;
  }