.new-gallery-grid-container {
    margin-top: 2rem;
    margin-left: 1%;
    display: grid;
    grid-template-columns: repeat(7, 200px);
    grid-template-rows: repeat(5, 135px);
    grid-gap: 1%;
    /* border: 1px solid black;
    box-sizing: border-box; */
 
  }
  
  .new-gallery-grid-item {
     /* border: 1px solid black;
    box-sizing: border-box; */

  }

  .new-gallery-grid-item img{
    width: 100%;
    height: 100%;
  }