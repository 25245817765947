.navbarContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 95px;
}
.logoBlock {
  width: 190px;
  height: 30px;
  display: inline-block;
}
.logoBlock {
  text-decoration: none;
}
.homepageLogo{
  margin-bottom: 22px;
}
.navbarItemBlock {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.navbarList {
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0px;
  display: flex;
  align-items: center;
}
.navbarList li {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5px 10px;
  font-size: 15px;
  line-height: 13px;
  font-weight: 400;
  letter-spacing: 0.92px;
  cursor: pointer;
  transition: 0.3s linear;
  position: relative;
}
.hideForMob.dropMenu .navbarList__item {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbarList__item {
  text-decoration: none;
  color: #ffffff;
  transition: 0.3s linear;
margin: 0;
}
.navbarList__item-drawer{
  text-decoration: none;
  color: #ffffff;
  transition: 0.3s linear;
}
.logout-btn{
  border: 1px solid white;
  padding: 8px 20px;
  font-size: 17px;
  border-radius: 5px;
}
.btn--outlined:hover{
  color: black !important;
}

/* button design */

.navbarList .btn {
  position: relative;
  min-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
}
.btn.btn--outlined {
  background: transparent;
  color: #ffffff;
  transition: all 0.3s linear;
  border: 1px solid #ffffff;
}
.btn {
  padding: 5px 10px;
  border: 0px;
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 0;
  text-decoration: none;
}
.iconIn.iconIn--call {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: url(https://atmantan.com/wp-content/themes/atmantanresorts/assets/images/phone.svg)
    no-repeat;
  background-position: center;
  background-size: contain; */
}
.iconIn--call .icon{
  /* color: #ffffff; */
}

.iconIn.iconIn--call:hover .iconIn--call .icon{
color: black !important;
}

.iconIn {
  display: inline-block;
}
.btn {
  cursor: pointer !important;
}
.navbarList__item:hover {
  color: #CFAE60;
  /* font-weight: 700; */
}

/* .navbarList__item:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: -1px;
    top: -1px;
    background: transparent;
    border: 1px solid #ffffff;
    transform: rotateY(90deg);
    transition: all 0.3s linear;
} */

/* shopping cart */
.shopping-cart {
  position: relative;
}
.shopping-number {
  position: absolute;
  top: -10px;
  left: 12px;
  font-weight: 700;
  font-size: 23px;
  color: #b2913e;
}

.hamburger2{
  display: none!important;
}


.hamburger {
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  cursor: pointer;
}
.hamburger:before {
  top: 5px;
  transform: rotate(0deg);
  transition: 0.3s linear;
}
.hamburger:before, .hamburger:after {
  content: "";
  width: 32px;
  height: 3px;
  background: #ffffff;
  position: absolute;
  left: 0px;
}
.hamburger:after {
  top: 24px;
  transform: rotate(0deg);
  transition: 0.3s linear;
}
.hamburgerLine {
  width: 32px;
  height: 3px;
  background: #ffffff;
  position: absolute;
  left: 0px;
  top: calc(50% - 1.5px);
  opacity: 1;
  transition: all 0.3s linear;
}
/* responsive */



@media only screen and  (max-width: 770px) {
  .hideForMob {
    display: none !important;
  }
  .hamburger2{
    display: block !important;
   
  }
  .navbarItemBlock{
    display: none !important;
  }
}
