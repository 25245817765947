@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300&family=Montserrat&display=swap');

.privacy{
  font-family: 'Barlow', sans-serif;
  font-family: 'Montserrat', sans-serif;
  color: #545454;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}

.bold{
  font-weight: 700;
}



.privacy li {
  background: url(https://inspyria.com/Themes/ETR/images/select-arrw-yellow-rgt.png)
    no-repeat 0 11px;
  padding-left: 15px;
}

td>p{
  margin:10px;
}