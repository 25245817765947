

.footerSection {
  background: #1B1F07;
}
.pageSection {
  padding: 97px 0px 90px 0px;
}
.container {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
 
  /* padding: 0px 15px; */
}
.footerListBoxWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerListBox {
  margin: 0px 15px;
}
.footerListHolder {
  margin-bottom: 35px;
}
.footerListHead {
  font-size: 18px;
  line-height: 21px;
  color: #c0a664;
  margin: 0px 0px 20px 0px;
}
.footerList {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
.linkWhite {
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}
.linkWhite:hover {
  /* content: "";
    width: 100%;
    height: 1px; */
  color: #c0a664;
  /* background: #ffffff; */
  /* position: absolute;
    left: 0px;
    bottom: -2px;
    transform: rotateY(90deg);
    transition: 0.3s linear; */
}
.textRight {
  text-align: right;
}
.footerRightList {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
.footerRightList__item {
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: #a3a3a3;
  text-decoration: none;
  transition: all 0.3s linear;
}
.copyWriteBlock {
  border-top: 1px solid #606060;
  width: calc(100% - 30px);
  margin: 0 auto;
  padding-top: 24px;
  margin-top: 40px;
}
.flexEB {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.copyWriteText {
  width: 50%;

  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
}
.payment-images-container{
  display: flex;
  width: 100%;
  justify-content: center;
}
.addressText {
  /* width: 50%; */
  text-align: right;
  font-size: 18px;
  line-height: 30px;
  margin: 0px;
  color: #ffffff;
  margin-left: auto;
}
.addressLink {
  color: #ffffff;
  text-decoration: none;
}
.footer-icon{
  font-size: 40px !important;
  margin: 10px;
  color: #ffffff;
}
.footer-icon-container{
  text-align: center;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .container {
    width: 100% !important;
  }
  .footerListBox.textRight {
    text-align: center;
    margin: 20px 0px;
    justify-content: center;
  }
  .footerListHolder {
    /* padding: 15px; */
  }
  .footerListBox,.social-links-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 5px;
  }
  .footerListBoxWrapper {
    flex-direction: column;
    width: calc(70% );
    /* margin: 0px -15px; */
  }
  .copyWriteBlock {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .copyWriteText>span{
    display: flex;
  }
}

@media (max-width: 600px){
  .footerListBox{
    margin-left: 6.5rem;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* border: 1px solid wheat; */
  
  }
  .payment-images-container{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}